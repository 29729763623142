.aistem-mini {
    min-width: 280px;
    width: fit-content;
    /* height: 50px; */
    background-color: #000;
    outline: 1px solid #ffffff12;
    position: fixed;
    top: 16px;
    left: calc(50% + 90px);
    transform: translateX(-50%);
    border-radius: 25px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999999;
    transition: all 400ms, left 400ms 200ms;
    flex-wrap: wrap;
    /* min-width: 260px; */
    /* background-color: #000; */
    /* max-width: 70px; */
    /* box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.7); */
    /* outline: 1px solid #f00; */
    height: fit-content;
    padding: 4px;
    outline: 1px solid #434343;
    box-shadow: 0px 4px 10px #000000b0;
}

.aistem-mini.aistem-starbox {
    left: calc(70%);
}

.aistem-mini-bg {
    position: fixed;
    width: 100vw;
    height: 100dvh;
    top: 0px;
    left: 0px;
    background-color: #000000d4;
    z-index: 999999999;
    pointer-events: none;
    opacity: 0;
    transition: all 300ms;
}

.aistem-mini-bg.shown {
    pointer-events: all;
    opacity: 1;
}

.aistem-mini.transformed {
    height: fit-content;
    /* box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.7); */
    /* margin: 0px; */
}

.aistem-mini.transformed2 {
    /* height: fit-content; */
    left: calc(50%);
    /* box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.7); */
    /* width: 90vw; */
    /* margin: 0px; */
    transition: all 400ms;
    max-width: min(90vw, 800px);
    margin-left: 10px;
}

.aistem-mini-row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.aistem-mini.transformed .aistem-mini-text {
    max-width: 0px;
    overflow: hidden;
    opacity: 0;
    margin: 0px;
}

.aistem-mini.transformed2 .aistem-mini-text {
    max-width: 0px;
    overflow: hidden;
    opacity: 0;
    margin: 0px;
}

.aistem-mini.listening {
    outline: 3px solid #1567d2;
    box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.7);
    z-index: 9999999999;
}

.aistem-mini-logo {
    scale: 0.3;
    position: relative;
    /* margin-bottom: 5px; */
    margin: -40px -40px -35px;
    transition: all 1400ms, margin 500ms;
}

.aistem-mini.transformed .aistem-mini-logo {
    /* opacity: 0; */
    margin: -30px -40px -25px;
}

.aistem-mini.speaking .aistem-mini-logo {
    opacity: 0;
    margin: -16px -40px;
}

.aistem-gif {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 5px;
    height: 70px;
    opacity: 0;
    transition: all 200ms;
    pointer-events: none;
}

.aistem-gif.shown {
    opacity: 1;
    transition: all 0ms;
}

.aistem-gif.shown.shownfirst {
    transition: all 200ms;
}

.aistem-mini-text {
    padding-right: 10px;
    transition: all 1000ms;
    margin-right: auto;
    white-space: nowrap;
    max-width: 500px;
}

.aistem-mic-btn {
    background-color: #313131;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 200ms, opacity 1000ms, max-width 1200ms;
    position: relative;
}

.aistem-mic-btn.muted {
    background-color: #c93838;
}

.aistem-mic-btn.muted img {
    filter: none;
}

.aistem-mic-btn::after {
    content: "";
    max-width: 0px;
    transition: max-width 400ms;
}

.aistem-mic-btn.muted::after {
    content: "";
    position: absolute;
    background-color: #fff;
    width: 23px;
    max-width: 23px;
    height: 2px;
    left: 50%;
    top: 51%;
    transform: translateX(-50%) translateY(-50%) rotateZ(45deg);
    outline: 1px solid #c93838;
}

.aistem-mini.speaking .aistem-mic-btn {
    opacity: 0;
    /* max-width: 0px; */
    /* margin: 0px; */
}

.aistem-mic-btn img {
    height: 18px;
    filter: brightness(0.7);
}

.aistem-speak {
    width: 100%;
    padding: 0px 20px 20px;
    text-align: justify;
}

.aistem-mini.listening .aistem-mic-btn {
    background-color: #1567d2;
}

.aistem-mic-btn.speaking {
    width: 40px;
}

.aistem-mini.listening .aistem-mic-btn img {
    height: 18px;
    filter: none;
}

.aistem-user-speaking {
    display: table;
    /* margin: 140px auto; */
    /* width: 350px; */
    /* height: 320px; */
    position: relative;
    width: 30px;
    height: 30px;
    /* outline: 1px solid #f00; */
    margin-left: 7px;
}

.aistem-user-speaking span {
    position: absolute;
    width: 4px;
    height: 10px;
    border-radius: 50%;
    margin: auto;
    top: 0;
    bottom: 0;
    transition: all 03s;
    background-color: #fff;
    scale: 0.7;
}

.aistem-user-speaking span:nth-child(2) {
    left: 6px;
}

.aistem-user-speaking span:nth-child(3) {
    left: 12px;
}

.aistem-user-speaking span:nth-child(4) {
    left: 18px;
}

.blue {
    animation: sound-2 1.2s infinite ease-in-out alternate;
}

.red {
    animation: sound-1 1.2s infinite ease-in-out alternate;
}

.yellow {
    animation: sound-2 1.2s infinite ease-in-out alternate;
}

.green {
    animation: sound-1 1.2s infinite ease-in-out alternate;
}

@keyframes updown {
    0% {
        top: 0;
    }
    50% {
        top: 15px;
    }
    100% {
        top: -15px;
    }
}

@keyframes sound-1 {
    0% {
        height: 28px;
        border-radius: 30px;
    }
    10% {
        height: 19px;
        border-radius: 30px;
    }
    20% {
        height: 26px;
        border-radius: 30px;
    }
    25% {
        height: 18px;
        border-radius: 30px;
    }
    30% {
        height: 28px;
        border-radius: 30px;
    }
    40% {
        height: 19px;
        border-radius: 30px;
    }
    50% {
        height: 16px;
        border-radius: 30px;
    }
    60% {
        height: 13px;
        border-radius: 30px;
    }
    70% {
        height: 10px;
        border-radius: 30px;
    }
    80% {
        height: 13px;
        border-radius: 30px;
    }
    85% {
        height: 10px;
        border-radius: 30px;
    }
    90% {
        height: 13px;
        border-radius: 30px;
    }
    95% {
        height: 10px;
        border-radius: 30px;
    }
    100% {
        height: 5px;
        border-radius: 50%;
    }
}

@keyframes sound-2 {
    0% {
        height: 18px;
        border-radius: 30px;
    }
    20% {
        height: 12px;
        border-radius: 30px;
    }
    30% {
        height: 18px;
        border-radius: 30px;
    }
    40% {
        height: 12px;
        border-radius: 30px;
    }
    50% {
        height: 11px;
        border-radius: 30px;
    }
    60% {
        height: 9px;
        border-radius: 30px;
    }
    70% {
        height: 7px;
        border-radius: 30px;
    }
    80% {
        height: 9px;
        border-radius: 30px;
    }
    90% {
        height: 7px;
        border-radius: 30px;
    }
    100% {
        height: 5px;
        border-radius: 50%;
    }
}

.aistem-dialog-text {
    background-color: #1a1a1a;
    padding: 10px 12px;
    border-radius: 8px;
    white-space: pre-wrap;
}

.first-steps-modal-bg {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999999999;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 200ms;
    color: #fff;
}

.aistem-modal-bg {
    z-index: 999999;
}

.first-steps-modal-bg.shown {
    opacity: 1;
    pointer-events: all;
}

.first-steps-modal {
    background-color: #000;
    width: 95vw;
    max-width: 700px;
    height: 500px;
    max-height: 500px;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    outline: 1px solid rgba(255, 255, 255, 0.183);
    padding: 20px;
    box-sizing: border-box;
}

.spacex-circle {
    position: absolute;
    width: 1000px;
    height: 1000px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0);
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% - 100px);
    transition: all 1000ms 200ms ease-in-out, box-shadow 1000ms 300ms, outline 1000ms 300ms;
    /* background-color: #030c1e; */
    box-shadow: inset 0px 0px 0px 30px #01081500;
    outline: 30px solid #01081500;
    backface-visibility: hidden;
    background-color: #000409;
}

.first-steps-modal-bg.shown .spacex-circle {
    box-shadow: inset 0px 0px 0px 30px #010815;
    outline: 30px solid #010815;
    border: 2px solid rgba(255, 255, 255, 0.641);
}

.actual-stage {
    position: absolute;
    bottom: 26px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    font-weight: 500;
}

.dot {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 50%;
    left: 50%;
    top: 0px;
    transform: translateX(-50%) translateY(-50%);
    transition: all 1000ms 200ms ease-in-out, font-weight 0ms;
    cursor: pointer;
}

.dot:not(.selected):hover {
    font-weight: 600;
    scale: 1.1;
}

.dot.disabled .dot-name {
    color: #8e8e8e;
}

.dot.disabled {
    background-color: #8e8e8e;
    pointer-events: none;
}
.dot.selected {
    cursor: default;
}

.dot-name {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
    font-size: 10px;
    white-space: nowrap;
    transition: all 300ms;
    padding: 10px 0px;
}

.dot.selected .dot-name {
    opacity: 0;
}

.first-steps-title {
    text-align: center;
    font-size: 20px;
}

.step-mini-einstein {
    pointer-events: none;
    scale: 0.2;
    bottom: 55px;
    position: absolute;
    left: 50%;
    transform: translateX(-250%);
}

.first-steps-message {
    display: flex;
    white-space: pre-wrap;
    align-items: flex-start;
    margin-top: 20px;
}

.first-steps-text {
    margin: 14px;
    margin-top: 5px;
    font-size: 14.5px;
    position: relative;
    text-align: justify;
}

.first-steps-einstein {
    scale: 0.35;
    margin: -40px;
    margin-top: -50px;
}

.first-steps-modal-btn {
    background-color: #1567d2;
    padding: 4px 20px;
    font-size: 15px;
    width: fit-content;
    border-radius: 6px;
    /* position: absolute; */
    /* right: 10px; */
    /* bottom: 0px; */
    cursor: pointer;
    transition: all 300ms;
    opacity: 0;
    pointer-events: none;
    margin-right: 10px;
    display: inline-block;
    color: #fff;
}

.first-steps-links {
    margin-bottom: 20px;
    margin-top: 10px;
}

.first-steps-links .first-steps-modal-btn {
    background-color: #2d2d2d;
}

.first-steps-modal-btn.shown {
    opacity: 1;
    pointer-events: all;
}

.first-steps-modal-btn.shown.disabled {
    background-color: #272727;
    color: #717171;
    pointer-events: none;
}

.first-steps-modal-btn:hover {
    opacity: 0.8;
}

.first-steps-text span::after {
    display: none;
}

.first-steps-open {
    position: absolute;
    right: 10px;
    bottom: 100%;
    margin: 4px 0px;
    color: #1567d2;
    font-weight: 500;
    cursor: pointer;
    font-size: 15px;
    opacity: 0;
    pointer-events: none;
    transition: all 200ms;
}

.first-steps-open.shown {
    opacity: 1;
    pointer-events: all;
}

.first-steps-open:hover {
    font-weight: 600;
}

.first-steps-fulfilled {
    width: 16px;
    height: 16px;
    background-color: #262626;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    border-radius: 2px;
}

.first-steps-fulfilled::after {
    content: "";
    position: absolute;
    width: 6px;
    height: 12px;
    border-bottom: 4px solid #1567d2;
    border-right: 4px solid #1567d2;
    top: -2px;
    left: 3px;
    transform: rotateZ(45deg);
    opacity: 0;
    scale: 0.1;
    transition: all 200ms;
}

.first-steps-condition {
    margin-top: 10px;
    display: flex;
    align-items: center;
    transition: all 200ms;
}

.first-steps-condition.fulfilled {
    text-decoration: line-through;
}

.first-steps-condition.fulfilled .first-steps-fulfilled::after {
    opacity: 1;
    scale: 1;
}

.first-steps-buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    /* outline: 1px solid #f00; */
}

.first-steps-bonus {
    font-size: 13px;
    /* width: 95px; */
    margin-left: 10px;
    text-align: center;
    /* color: rgb(9, 133, 9); */
    color: #afafaf;
    /* color: #1567d2; */
    font-weight: 700;
    padding: 4px 4px 4px 8px;
    outline: 1px solid #2aad10;
    border-radius: 6px;
    /* line-height: 13px; */
    display: flex;
    align-items: center;
}

.first-steps-bonus strong {
    /* color: #000; */
    background-color: #2aad10;
    color: #fff;
    padding: 0px 4px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    /* margin-bottom: 2px; */
    display: inline-flex;
    /* animation: bonus-animation 1000ms linear infinite; */
    animation-timing-function: steps(1);
    display: inline-flex;
    width: fit-content;
    align-items: flex-end;
    margin-left: 2px;
}

.first-steps-bonus strong small {
    margin-bottom: 1px;
    margin-right: 2px;
    font-size: 12px;
}

.bottom-bonus {
    color: rgb(9, 133, 9);
    font-weight: 700;
}

@keyframes bonus-animation {
    50% {
        color: #2aad10;
        background-color: #000;
    }
}

.image-modal-bg {
    background-color: rgba(0, 0, 0, 0.85);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 9999999999;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 300ms;
}

.image-modal-bg-aistem {
    background-color: rgba(0, 0, 0, 0.95);
}

.image-modal {
    background-color: #0c0c0c;
    width: fit-content;
    height: 90%;
    max-height: 90%;
    border-radius: 20px;
    scale: 0.1;
    transition: all 600ms, transform 600ms;
    box-sizing: border-box;
    display: flex;
    border: 1px solid rgba(128, 128, 128, 0.443);
    font-size: 18px;
    transform: translateY(-400px);
    padding: 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.image-modal img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
}

.image-modal-bg.shown {
    opacity: 1;
    pointer-events: all;
}

.image-modal-bg.shown .image-modal {
    scale: 1;
    transform: translateY(0px);
}

@media only screen and (max-width: 768px) {
    .aistem-mini {
        left: 50%;
        top: 50px;
        font-size: 12px;
        min-width: 240px;
        padding: 0px;
        transition: all 32, 0ms, left 400ms 200ms;
    }

    .aistem-mini.hidden {
        top: -60px;
    }

    .aistem-mini.transformed2 {
        left: calc(50% - 10px);
        z-index: 999999999;
        top: 40px;
        padding-top: 5px;
        border-radius: 18px;
    }

    .aistem-mini.transformed {
        z-index: 999999999;
    }

    .aistem-mini-bg {
        z-index: 999999999;
    }

    .aistem-mini-text span::after {
        display: none;
    }

    .first-steps-text {
        font-size: 13px;
        margin-top: 0px;
    }

    .first-steps-message {
        margin-top: 6px;
    }

    .first-steps-bonus {
        display: flex;
        flex-direction: column;
        max-width: 80px;
    }
    .first-steps-bonus strong {
        margin: 4px 0px 4px;
    }
    .first-steps-modal-btn {
        font-size: 13px;
        padding: 4px 6px;
        margin-bottom: 2px;
        max-width: fit-content !important;
        text-align: left;
        width: fit-content;
        min-width: fit-content;
        /* width: min-content; */
    }
    .first-steps-buttons {
        margin-top: 8px !important;
    }
    .first-steps-links {
        margin-bottom: 10px;
    }

    .first-steps-einstein {
        scale: 0.3;
        margin: -45px -50px 0px -50px;
    }

    .first-steps-modal {
        max-height: 80dvh;
        height: 80dvh;
        max-width: 90vw;
    }
}

.aistem-mini.fullscreen {
    min-width: 280px;
    max-width: 280px;
    min-height: 280px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    background-color: #000;
    outline: 0px solid #000;
    pointer-events: all;
}

.aistem-mini.fullscreen.speaking {
    background-color: #000;
}

.aistem-fullscreen-back {
    color: #fff;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 999999999;
    opacity: 0.2;
}

.aistem-mini.fullscreen:not(.aistem-background-listening).listening {
    box-shadow: none;
}

.aistem-mini.fullscreen.aistem-background-listening {
    position: absolute;
    min-width: 300px;
    max-width: 300px;
    min-height: 300px;
    background: linear-gradient(#093e83, #afc9eb, #093e83);
    outline: none;
    animation: aistem-spin 3s linear infinite;
    transform: all 200ms;
    opacity: 0;
}

.aistem-mini.fullscreen.aistem-background-listening.shown {
    opacity: 1;
    box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.7);
}

@keyframes aistem-spin {
    0% {
        transform: translateY(-50%) translateX(-50%) rotateZ(0deg);
    }
    100% {
        transform: translateY(-50%) translateX(-50%) rotateZ(360deg);
    }
}

.aistem-mini.fullscreen .aistem-mini-logo {
    scale: 1.2;
    /* opacity: 0.9; */
}

.aistem-mini.fullscreen .aistem-mini-text,
.aistem-mini.fullscreen .aistem-mic-btn {
    display: none;
}

.aistem-mini.fullscreen .aistem-speak {
    position: absolute;
    left: calc(100% + 100px);
    max-width: 600px;
    width: 600px;
    font-size: 20px;
}

.aistem-mini.fullscreen .aistem-speak .aistem-dialog-text {
    background-color: #000;
    font-size: 20px;
}

.aistem-mini.fullscreen.transformed {
    left: 30%;
}

.aistem-mini.fullscreen .aistem-gif {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 260px;
    /* filter: brightness(100); */
    /* mix-blend-mode: difference; */
    /* opacity: 1; */
    /* background-color: #0d0d0d; */
}

.aistem-mini.fullscreen.muted {
    outline: 10px solid rgb(141, 14, 14);
}

.aistem-mini-bg.fullscreen {
    opacity: 1;
    pointer-events: all;
    background-color: #000;
}

.aistem-mini.fullscreen .einstein .tongue {
    outline: 4px solid #000;
}
