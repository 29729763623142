.navbar {
    flex: 1;
    background-color: #171717;
    color: #fff;
    padding: 24px;
    height: 100vh;
    width: 200px;
    box-sizing: border-box;
    position: fixed;
    z-index: 999999;
    transition: all 300ms, z-index 0ms 300ms;
}

.navbar.modal {
    z-index: 999999999;
    transition: none;
}

.drop-logo {
    width: 150px;
}

.wallet-more {
    /* outline: 1px solid #1567D2; */
    /* background: linear-gradient(to right, #00000000 0%,#1567D2 50%,#00000000 100%); */
    position: absolute;
    bottom: 2px;
    z-index: -1;
    font-size: 11px;
    /* background: linear-gradient(to bottom, #262626 20%, #1567d2 100%); */
    left: 0px;
    border-radius: 0px 0px 8px 8px;
    color: #1567d2;
    font-weight: 500;
    transition: all 200ms;
    /* opacity: 0; */
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    background-color: #262626;
    padding: 4px 0px;
}

.wallet-more::before {
    content: "";
    position: absolute;
    background: linear-gradient(to bottom, #262626 80%, #1567d280 100%);
    /* background-color: #1567d2; */
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    border-radius: 0px 0px 7px 7px;
    z-index: -5;
}

.wallet-more:hover {
    font-weight: 600;
}

.wallet-1:hover .wallet-more {
    bottom: -16px;
    opacity: 1;
}

.wallet-container.turned-1 .wallet-more,
.wallet-container.turned-2 .wallet-more {
    transition: all 0ms;
    opacity: 0;
}

.wallet-container {
    perspective: 1000px;
    margin: 20px 0px 30px;
}

.wallet {
    width: 150px;
    height: 100px;
    position: relative;
    transform: rotateX(2deg) rotateY(0deg) translateZ(44px);
    transform-style: preserve-3d;
    transition: all 300ms;
    /* animation: wallet 4s linear infinite; */
}

.wallet-container.turned-1 .wallet {
    transform: rotateX(2deg) rotateY(-120deg) translateZ(44px);
}

.wallet-container.turned-2 .wallet {
    transform: rotateX(2deg) rotateY(-240deg) translateZ(44px);
}

.wallet-1,
.wallet-2,
.wallet-3 {
    height: 95px;
    position: absolute;
    background-color: #262626;
    width: 100%;
    height: 100%;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 8px;
    /* outline: 1px solid #f00; */
    font-size: 12px;
}

.wallet-2 {
    text-align: center;
    transform-origin: right;
    transform: rotateY(-60deg) rotateY(-180deg) translateX(100%);
}

.wallet-3 {
    text-align: center;
    transform-origin: left;
    transform: rotateY(60deg) rotateY(-180deg) translateX(-100%);
}

.wallet .wallet-value {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: flex-end;
    margin: 4px 0px 8px;
}

.wallet .roulette {
    transition-delay: 100ms;
}

.wallet .add-credits-btn {
    background-color: #1567d2;
    text-align: center;
    padding: 4px;
    margin-top: 4px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    font-size: 14px;
    transition: all 200ms;
}

.add-credits-btn:hover {
    /* scale: 1.03; */
    background-color: #0f4c9c;
}

.method {
    margin-bottom: 8px;
}

.credits-insert-value {
    width: 100%;
    box-sizing: border-box;
    margin: 2px 0px 3px;
    padding: 5px;
    padding-left: 20px;
    border-radius: 6px;
    background-color: #1f1f1f;
    color: #fff;
    text-align: left;
    font-size: 18px;
    border: none;
    outline: 1px solid #1567d200;
    transition: all 200ms;
    text-align: center;
}

.credits-insert-value-container {
    position: relative;
}

.credits-insert-value-container::after {
    content: "R$";
    position: absolute;
    left: 10px;
    bottom: 12px;
    opacity: 0.7;
    /* outline: 1px solid #f00 */
}

.credits-insert-value:focus {
    outline: 1px solid #1567d2;
}

.add-credits-back {
    margin: 4px 0px;
    font-size: 11px;
    cursor: pointer;
    transition: all 100ms;
}

.add-credits-back:hover {
    font-weight: 500;
}

.wallet-credits-explanation {
    position: absolute;
    /* outline: 1px solid #f00; */
    left: calc(100% + 10px);
    top: 50%;
    width: 220px;
    /* height: 110px; */
    padding: 10px;
    box-sizing: border-box;
    background-color: #212121;
    border-radius: 8px;
    box-shadow: 1px 1px 5px 3px #00000030;
    border: 1px solid #ffffff31;
    font-size: 11px;
    text-align: justify;
    transform: translateY(-50%) translateX(-40px);
    opacity: 0;
    transition: all 200ms ease-in-out;
    pointer-events: none;
}

.wallet-container.turned-1 .wallet-credits-explanation {
    transform: translateY(-50%) translateX(0px);
    opacity: 1;
}

.wallet-credits-explanation::before {
    content: "";
    position: absolute;
    left: -8px;
    transform: rotate(135deg) translateX(-50%);
    height: 8px;
    width: 8px;
    top: 50%;

    background-color: inherit;
    box-shadow: inherit;
    border-bottom: inherit;
    border-right: inherit;
    box-shadow: inherit;
    clip-path: polygon(85% 0, 100% 0, 100% 100%, 0 100%, 0 83%);
}

.wallet-methods {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0px;
}

.wallet-methods img {
    width: 45px;
    height: 45px;
    outline: 1px solid #ffffff2d;
    border-radius: 6px;
    object-fit: contain;
    padding: 2px 8px;
    transition: all 200ms;
    cursor: pointer;
}

.wallet-methods img:hover {
    background-color: #ffffff10;
}

.wallet-methods img:first-of-type {
    /* width: 45px; */
}

.lateral-menu {
    list-style-type: none;
    font-size: 15px;
    font-weight: 500;
}

.lateral-menu li {
    margin-top: 16px;
}

.lateral-menu li a {
    transition: all 200ms 100ms, color 100ms;
    color: #fff;
    display: flex;
    align-items: center;
}

.lateral-menu .current-page {
    color: #1567d2;
    font-weight: 600;
    pointer-events: none;
}

.lateral-menu li a:hover {
    color: #666666;
}

.lateral-sub {
    display: block;
    margin-left: 16px;
    font-size: 14px;
    max-height: 0px;
    overflow: hidden;
    opacity: 0;
}

.lateral-menu li:hover .lateral-sub {
    margin-top: 12px;
    max-height: 200px;
    opacity: 1;
}

.lateral-menu .current-page.lateral-sub {
    margin-top: 12px;
    max-height: 200px;
    opacity: 1;
}

.lateral-arrow {
    display: inline-block;
    border: 1px solid;
    border-top: none;
    border-left: none;
    transform: rotateZ(45deg);
    width: 6px;
    height: 6px;
    margin-left: 16px;
    margin-bottom: 3px;
}

.navbar-user-container {
    width: 100%;
    box-sizing: border-box;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0px;
    padding: 0px 20px;
}

.navbar-user {
    display: flex;
    font-size: 16px;
    align-items: center;
    cursor: pointer;
}

.navbar.disabled .navbar-user,
.navbar.disabled .wallet-container,
.navbar.disabled .navbar-config li:first-of-type,
.navbar.disabled .lateral-menu li a:not(.current-page),
.navbar.disabled .drop-logo {
    filter: blur(1px);
    opacity: 0.25;
    pointer-events: none;
}

.navbar-user-img {
    width: 40px;
    height: 40px;
    background-color: #171717;
    border-radius: 50%;
    margin-right: 10px;
    /* border: 2px solid #fff; */
    outline: 2px solid #fff;
    object-fit: cover;
}

.navbar-account-type {
    font-size: 12px;
    color: #909090;
}

.navbar-user:hover .navbar-account-type {
    font-weight: 600;
}

.navbar-username {
    font-weight: 600;
}

.navbar-config {
    /* position: absolute; */
    /* bottom: 0px; */
    list-style-type: none;
    transition: all 200ms 200ms;
    opacity: 0;
    max-height: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 0px;
    pointer-events: none;
    z-index: -1;
}

.navbar-user-container:hover .navbar-config {
    opacity: 1;
    max-height: 100px;
    margin-top: 8px;
    pointer-events: all;
    z-index: 0;
}

.navbar-config li a {
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-top: 8px;
    transition: all 200ms;
}

.navbar-config li a:hover {
    filter: brightness(0.5);
}

.config-icon {
    width: 17px;
    margin-right: 12px;
}

.wallet .wallet-value .wallet-value-number {
    margin-left: 5px;
    height: 28px;
}

.config-modal-bg {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 200ms;
}

.config-modal-bg.shown {
    opacity: 1;
    pointer-events: all;
}

.config-modal {
    width: 300px;
    border-radius: 20px;
    border: 1px solid rgba(128, 128, 128, 0.443);
    scale: 0;
    opacity: 0;
    transition: all 300ms, opacity 100ms;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    background-color: #090909;
    max-height: 90vh;
    /* overflow-y: hidden; */
}

.config-modal-bg.shown .config-modal {
    scale: 1;
    opacity: 1;
}

.config-bool .switch {
    position: relative;
    display: inline-block;
    min-width: 40px;
    height: 20px;
    margin: 0px;
    margin-left: 10px;
}

.config-bool .switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #1d1d1d;
    transition: 0.4s;
    border-radius: 34px;
}

.config-bool .switch.checked .slider {
    background-color: #1567d2;
}

.config-bool .switch .slider::before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 6px;
    bottom: 4px;
    background-color: #b1b1b1;
    transition: 0.4s;
    border-radius: 50%;
}

.config-bool .switch.checked .slider::before {
    transform: translateX(18px);
    background-color: #ffffff;
}

.config-slider {
    width: 100%;
}

.config-slidecontainer {
    margin-top: 2px;
    width: 100%;
}

.config-slider-input {
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 5px;
    outline: none;
    opacity: 0.7;
    transition: opacity 200ms;
}

.config-slider-input:hover {
    opacity: 1;
}

.config-slider-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #1567d2;
    cursor: pointer;
}

.config-slider-input.zerovolume::-webkit-slider-thumb {
    background-color: rgb(232, 66, 66);
}

.config-name {
    display: flex;
    align-items: center;
    font-size: 14px;
    width: 100%;
}

.config-value {
    margin-left: auto;
    font-size: 12px;
    margin-top: 3px;
}

.conf-information {
    min-width: 12px;
    height: 12px;
    line-height: 12px;
    outline: 1px solid #fff;
    text-align: center;
    border-radius: 50%;
    font-size: 11px;
    opacity: 0.6;
    cursor: help;
}

.conf-information-container {
    margin-left: 8px;
    position: relative;
}

.conf-information-container:hover .conf-information-bubble {
    opacity: 1;
    transform: translateX(0px);
}

.conf-information-bubble {
    position: absolute;
    background-color: #090909;
    width: 145px;
    text-align: justify;
    height: fit-content;
    padding: 6px 8px;
    border-radius: 6px 6px 6px 0px;
    font-size: 12px;
    color: #d9d9d9;
    outline: 1px solid #ffffff4f;
    left: 14px;
    bottom: calc(100% + 4px);
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    transition: all 200ms;
    transform: translateX(-20px);
}

.config-bool {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    transition: all 200ms;
    max-height: 100px;
    box-sizing: border-box;
}

.config-bool.disabled {
    opacity: 0;
    pointer-events: none;
    max-height: 0px;
    margin: 0px;
}

.config-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 18px;
    width: 100%;
}

.config-title .einstein {
    scale: 0.3;
    margin: -20%;
    margin: -65px -40px -60px -55px;
}

.cut-hour {
    font-size: 18px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cut-hour-input {
    overflow: hidden;
    max-height: 0px;
    transition: all 400ms;
    padding: 0px;
    align-self: flex-start;
    padding: 0px 2px;
}

.cut-hour-input.shown {
    max-height: 100px;
    padding: 2px;
}

.cut-hour-input input {
    padding: 6px 10px;
    border-radius: 6px;
    border: none;
    outline: 1px solid #1567d200;
    transition: all 200ms;
    background-color: #181818;
    color: #fff;
    font-size: 20px;
    text-align: center;
    width: 80px;
}

.cut-hour-input input:focus {
    outline: 1px solid #1567d2;
}

.config-save-btn {
    /* position: absolute; */
    background-color: #1567d2;
    /* bottom: 24px; */
    /* right: 20px; */
    padding: 2px 10px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: auto;
    margin-top: 10px;
}

.cut-hour-explanation {
    font-size: 13px;
    text-align: justify;
    padding: 6px 8px;
    background-color: #181818;
    border-radius: 8px;
    margin-top: 4px;
}

.modal-mp {
    max-height: 90vh;
    /* outline: 1px solid #f00; */
    border-radius: 16px;
    transition: all 200ms;
    background-color: #090909;
    border: 1px solid hsla(0, 0%, 50%, 0.443);
    max-width: 90%;
    width: 400px;
    min-height: 160px;
    /* height: 300px; */
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
}

.modal-mp-content {
    overflow-x: auto;
    height: 100%;
    padding: 10px;
    /* outline: 1px solid #f00; */
    box-sizing: border-box;
}

.modal-mp-payment-btn {
    background-color: #1567d2;
    padding: 6px 10px;
    text-align: center;
    margin: -10px 10px 0px;
    z-index: 2;
    position: relative;
    border-radius: 6px;
    cursor: pointer;
}

.modal-mp-header {
    text-align: center;
    margin-top: -6px;
}

.modal-mp-loading {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    /* outline: 1px solid #f00; */
    top: 0px;
    left: 0px;
    background-color: #090909;
    z-index: 3;
    transition: all 200ms;
    opacity: 0;
    pointer-events: none;
    border-radius: 20px;
}

.modal-mp-loading.shown {
    opacity: 1;
    pointer-events: all;
}

.modal-mp-loading .loader {
    position: relative;
}

.wallet-payment-select {
    max-height: 0px;
    overflow: hidden;
    opacity: 0;
    transition: all 300ms height 0ms;
}

.wallet-payment-select.shown {
    max-height: 1000px;
    overflow: auto;
    opacity: 1;
}

.wallet-pix {
    max-height: 0px;
    overflow: hidden;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 300ms;
}

.wallet-pix.shown {
    max-height: 500px;
    margin-top: 20px;
    overflow: auto;
    opacity: 1;
}

.wallet-pix canvas {
    border-radius: 10px;
}

.wallet-pix-data {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wallet-pix-expire {
    background-color: #1f1f1f;
    padding: 4px 10px;
    border-radius: 4px;
    margin-left: 10px;
}

.wallet-pix-expire-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 14px;
}

.mp-header-value {
    margin-bottom: -4px;
    margin-right: 4px;
    font-weight: 500;
}

.wallet-pix-expire .roulette-container {
    margin-bottom: -4px;
}

.mp-credits-value {
    font-weight: 600;
    font-size: 14px;
}

.pix-copy-paste-container {
    background-color: #1567d2;
    border-radius: 6px;
    padding: 4px 12px;
    margin-top: 0px;
    transition: all 100ms;
}

.pix-copy-paste-container:hover {
    opacity: 0.8;
}

.wallet-mercadopago-data {
    padding: 10px;
    margin: 0px 10px;
    background-color: #1c1c1c;
    border-radius: 10px;
    text-align: justify;
    font-size: 14px;
    margin-bottom: 10px;
}

.wallet-mercadopago-btn {
    background-color: #1567d2;
    padding: 6px 10px;
    border-radius: 6px;
    margin-top: 10px;
    width: calc(100% - 20px);
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 15px;
}

.wallet-mercadopago-btn:hover {
    opacity: 0.8;
}

.wallet-status {
    margin-top: 10px;
    margin-bottom: -10px;
}

.extract-modal {
    width: 600px;
    max-width: 95%;
    border-radius: 20px;
    border: 1px solid rgba(128, 128, 128, 0.443);
    scale: 0;
    opacity: 0;
    transition: all 300ms, opacity 100ms;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    background-color: #090909;
    max-height: 90%;
    min-height: 300px;
    overflow-y: hidden;
}

.config-modal-bg.shown .extract-modal {
    scale: 1;
    opacity: 1;
}

.extract-modal .loader {
    position: relative;
    margin-top: 100px;
}

.extract-container {
    overflow-y: auto;
    width: 100%;
    margin-right: -4px;
    font-size: 14px;
    max-height: 0px;
    transition: all 1000ms 200ms ease-in;
    margin-top: auto;
}

.extract-container.shown {
    max-height: 1000px;
}

.extract-item {
    display: flex;
    margin-top: 1px;
    align-items: center;
}

.extract-item * {
    font-family: monospace, monospace;
}

.extract-movimentation {
    width: 60px;
    margin-left: 10px;
    text-align: right;
    margin-right: 6px;
}

.extract-movimentation.add {
    color: rgb(11, 184, 11);
}

.extract-movimentation.subtract {
    color: #c93838;
}

.extract-date {
    margin-right: auto;
}

.extract-modal-title {
    margin-bottom: 14px;
}

.extract-download-button {
    background-color: #1567d2;
    color: #fff;
    font-size: 13px;
    border-radius: 4px;
    padding: 1px 6px;
    position: absolute;
    right: 22px;
    top: 20px;
    opacity: 0;
    pointer-events: none;
    transition: all 200ms;
}

.extract-download-button.shown {
    opacity: 1;
    pointer-events: all;
}

.extract-download-button:hover {
    opacity: 0.8;
}

.empty-extract {
    width: 300px;
    margin-top: 90px;
}

.extract-reason {
    flex-basis: 1;
    /* outline: 1px solid #f00; */
    /* width: 240px; */
    align-self: flex-start;
    width: calc(100% - 220px);
}

.account-label {
    display: block;
    width: 100%;
    font-size: 12px;
    margin-top: 10px;
    color: #b4b4b4;
}

.account-label input {
    display: block;
    background-color: #181818;
    border: none;
    color: #fff;
    padding: 4px 10px;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    outline: 1px solid #1567d200;
    transition: all 200ms;
    margin-top: 2px;
    font-size: 14px;
}

.account-label input:focus {
    outline: 1px solid #1567d2;
}

.account-label input:disabled {
    color: #ffffff7b;
}

.account-label-address {
    display: flex;
    width: 100%;
}

.account-label-address .account-label {
    margin-right: 10px;
}

.account-label-address .account-label:last-of-type {
    margin-right: 0px;
}

.account-label.account-uf {
    width: 100px;
}

.config-modal-account {
    width: 400px;
    max-width: 90vw;
}

.account-label.account-cep {
    width: 180px;
}

.account-label.account-address-number {
    width: 120px;
    margin-bottom: 8px;
}

.config-modal.config-modal-code {
    font-size: 15px;
}

.input-account-name {
    max-width: 250px;
}

.account-photo {
    background-color: #090909;
    border: 1px solid #fff;
    width: 80px;
    height: 80px;
    position: absolute;
    right: 20px;
    top: 20px;
    border-radius: 50%;
    transition: all 200ms;
    overflow: hidden;
}

.account-photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.account-photo input {
    display: none;
}

.account-photo::after {
    content: "Alterar";
    background-color: #000000ce;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 200ms;
    opacity: 0;
    cursor: pointer;
    border-radius: 50%;
    font-size: 14px;
}

.account-photo:hover::after {
    opacity: 1;
}

.order-label {
    position: fixed;
    z-index: -1;
    background-color: #fff;
    width: 400px;
    height: 600px;
    color: #000;
    /* opacity: 0; */
    pointer-events: none;
    top: 0px;
    left: 0px;
}

.order-label-title {
    font-weight: 700;
    font-size: 18px;
    margin: 20px 20px 12px;
    padding: 4px 10px;
    border-radius: 8px;
    border: 2px solid #000;
    width: fit-content;
}

.order-label-text {
    font-size: 15px;
    font-weight: 500;
    margin: 0px 20px;
    text-align: justify;
    margin-bottom: 28px;
    line-height: 17px;
}

.order-label-phone {
    font-size: 24px;
    font-weight: 700;
    border: 2px solid #000;
    padding: 4px 15px 4px 10px;
    border-radius: 8px;
    width: fit-content;
    margin: 0px auto;
    position: relative;
    /* transform: translateX(-10px); */
}

.order-label-phone img {
    position: absolute;
    width: 38px;
    right: -27px;
    top: -14px;
}

.order-label-qr {
    /* padding: 8px; */
    border: 2px solid #000;
    width: fit-content;
    border-radius: 16px;
    overflow: hidden;
    margin: 20px auto 10px;
}

.order-label-starbox img {
    display: block;
    /* filter: invert(1); */
    margin: 0px auto;
    width: fit-content;
    height: 14px;
    padding-top: 14px;
    /* outline: 1px solid #f00; */
    /* object-fit: contain; */
    /* width: 100%; */
}

.order-label-starbox {
    margin-top: 20px;
    border-top: 1px solid #000;
}

.order-label-number {
    position: absolute;
    bottom: 44px;
    left: 4px;
    font-size: 14px;
    font-weight: 500;
}

.navbar-mobile-open {
    background-color: #0000009b;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100dvh;
    z-index: 999999999;
    pointer-events: none;
    opacity: 0;
    transition: all 600ms;
}

.navbar-mobile-open.shown {
    opacity: 1;
    pointer-events: all;
}

.navbar-mobile {
    width: 100%;
    height: 45px;
    /* background-color: #141414; */
    /* border-bottom: 1px solid #ffffff27; */
    position: fixed;
    top: 8px;
    left: 0px;
    z-index: 99999999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 10px;
    box-sizing: border-box;
    display: none;
    transition: all 300ms;
}

.navbar-mobile div {
    width: 40px;
}

.navbar-mobile img {
    height: 36px;
    box-shadow: 4px 4px 5px #00000031;
    background-color: #00000065;
    border-radius: 6px;
}

img.navbar-mobile-logo {
    height: 30px;
}

.navbar-mobile.hidden {
    top: -60px;
}

.navbar-user-img.user-img-mini {
    width: 30px;
    height: 30px;
    margin: 0px;
    outline: 2px solid #ffffff4f;
}

@media only screen and (max-width: 768px) {
    .navbar {
        top: 0px;
        transform: translateX(-100%);
        height: 100%;
        border-right: 1px solid #ffffff00;
    }

    .navbar.modal,
    .navbar.open-mobile {
        transition: all 300ms, z-index 0ms;
        transform: none;
        z-index: 999999999;
        border-right: 1px solid #ffffff17;
        box-shadow: 5px 0px 20px #000;
    }

    .lateral-menu .lateral-sub {
        margin-top: 12px;
        max-height: 200px;
        opacity: 1;
    }

    .navbar-config {
        opacity: 1;
        max-height: 100px;
        margin-top: 8px;
        pointer-events: all;
        z-index: 0;
    }

    .navbar-mobile {
        display: flex;
    }

    .wallet-1 .wallet-more {
        bottom: -15px;
        opacity: 1;
    }
}

/* Gab */
.franquia-container {
    background-color: #333;
    color: white;
    padding: 16px;
    border-radius: 10px;
    border: 2px solid #c93838;
    width: 100%;
    text-align: center;
    position: relative;
    box-sizing: border-box;
    gap: 5px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.franquia-container h3 {
    font-size: 18px;
}

.franquia-container p {
    font-size: 14px;
    font-weight: 500;
}

.ver-detalhes-btn {
    padding: 10px 20px;
    background-color: #c93838;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    font-weight: 600;
    font-size: 13px;
}

.popup-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.popup-content h2 {
    text-align: center;
    padding: 0px 20px 10px 20px;
}

.popup-content p {
    font-size: 16px;
    text-align: justify;
}

.popup-buttons {
    margin-top: 20px;
}

.antecipar-btn {
    padding: 10px 20px;
    background-color: #1567d2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
}

.antecipar-btn:hover {
    opacity: 0.8;
}

.renovar-btn {
    padding: 10px 20px;
    background-color: #1567d2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
}

.renovar-btn:hover {
    opacity: 0.8;
}

div.extract-modal.popup-franquia {
    min-height: fit-content;
}

.account-label.account-label-fit {
    /* width: fit-content; */
}

.faturador-btn {
    display: flex;
    margin-top: 28px;
    margin-left: 10px;
    padding: 3px 8px;
    background-color: #1a4b8b;
    border-radius: 4px;
    height: fit-content;
    font-size: 14px;
    cursor: pointer;
    transition: all 200ms;
}

.faturador-btn:hover {
    background-color: #1567d2;
    scale: 1.04;
}

.faturador-btn img {
    height: 18px;
    margin-right: 6px;
    transition: all 500ms;
}

.faturador-btn:hover img {
    transform: rotateZ(180deg);
    scale: 1.1;
}

.conf-information.conf-information-user {
    position: absolute;
    right: 6px;
    bottom: 8px;
    /* background-color: #fff; */
    outline-color: #1567d2;
    color: #1567d2;
    opacity: 1;
    font-weight: 700;
}

.conf-information-bubble.conf-bubble-user {
    width: 200px;
}

.faturador-popup {
    /* outline: 1px solid #f00; */
    position: absolute;
    top: 180px;
    right: 25px;
    background-color: #000;
    transition: all 600ms, opacity 200ms 300ms, scale 400ms;
    width: 100px;
    height: 15px;
    z-index: -1;
    opacity: 0;

    box-sizing: border-box;
    scale: 0.1;
    display: flex;
    flex-direction: column;
}

.faturador-popup.shown {
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    z-index: 999;
    opacity: 1;
    transition: all 400ms, opacity 200ms, scale 800ms;
    border-radius: 20px;
    padding: 20px;
    scale: 1;
}

.custom-file-upload input {
    display: none;
}

.custom-file-upload {
    display: inline-block;
    padding: 6px 10px;
    cursor: pointer;
    border-radius: 6px;
    /* margin-top: auto; */
    width: fit-content;
    transition: all 200ms;
    background-color: #1567d2;
    border: 1px solid #fff0;
}

.custom-file-upload:hover {
    background-color: #1567d2b0;
    scale: 1.02;
}

.custom-file-upload.selected {
    background-color: #000;
    border: 1px solid #ccc;
}

.cert-selected {
    font-size: 13px;
    margin-top: 4px;
    margin-left: 2px;
}

.cert-box {
    /* margin-top: 20px; */
    margin: 20px 0px auto;
    padding: 10px;
    outline: 1px solid #1567d2;
    border-radius: 6px;
    text-align: justify;
}

.cert-box.none {
    outline-color: rgb(232, 66, 66);
}

.cert-save {
    display: inline-block;
    margin-top: 16px;
    font-weight: 500;
    opacity: 0.7;
    pointer-events: none;
    background-color: #4a4a49;
    padding: 8px 16px;
    /* margin-top: 20px; */
    transition: all 200ms;
}

.cert-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    top: 0px;
    left: 0px;
    border-radius: 20px;
    opacity: 0;
    pointer-events: none;
}

.cert-save.enabled {
    background-color: #1567d2;
    opacity: 1;
    pointer-events: all;
}

.cert-loader .loader {
    position: relative;
}

.cert-loader.shown {
    opacity: 1;
    pointer-events: all;
}

.faturador-back {
    margin: 0px;
    cursor: pointer;
    transition: all 200ms;
    width: min-content;
    padding: 0px;
}

.faturador-back:hover {
    scale: 1.05;
}

#cert-password {
    font-size: 20px;
    padding: 4px 10px;
}
