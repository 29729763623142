.admin-order-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 30px;
    z-index: 99999;
    margin-bottom: 200px;
    /* overflow-y: scroll; */
    min-height: calc(100vh - 220px);
    height: fit-content;
    padding: 0px 30px;
    box-sizing: border-box;
}

.admin-order-container-wrapper {
    max-height: calc(100vh - 80px);
    /* overflow: auto; */
    z-index: 99999;
    position: relative;
}

.admin-order {
    background-color: #111111;
    max-width: 900px;
    width: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    margin-bottom: 8px;
    border-radius: 10px;
    font-size: 10px;
    box-sizing: border-box;
    padding-bottom: 0px;
    position: relative;
    transform: translateX(14px);
    padding-right: 114px;
}

.admin-order::after {
    content: "";
    /* outline: 1px solid #0f0; */
    width: calc(100% + 80px);
    height: calc(100% + 8px);
    top: 0px;
    position: absolute;
    border: 2px solid #fff;
    border-bottom: none;
    border-top: none;
    border-color: inherit;
    /* margin: 0px 40px; */
    box-sizing: border-box;
    left: calc(50% - 14px);
    transform: translateX(-50%);
    pointer-events: none;
}

.admin-order.admin-order-static2::after {
    left: 50%;
}

.admin-order:hover {
    z-index: 9999;
}

.renderIfVisible .admin-order {
    margin: 0;
}

.renderIfVisible.admin-order {
    padding: 0;
}

.admin-order.cut-hour {
    background-color: rgb(0, 39, 0);
}

.admin-order:focus,
.admin-order.focused {
    background-color: #2d2d2d;
    outline: 3px solid #fff;
}

.admin-order-menu {
    width: 100%;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin-order-btns {
    display: flex;
    margin: 0px 10px;
    pointer-events: all;
}

.admin-order-btns div {
    background-color: #222222;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    transition: all 200ms;
}

.admin-order-btns div.printed {
    background-color: #0a770a;
}

.admin-order-btns div:hover {
    background-color: #434343;
}

.admin-order-btns div {
    margin-right: 10px;
}

.admin-order-btns div:last-of-type {
    margin: 0px;
}

.admin-order-username {
    margin-left: auto;
    background-color: #222222;
    font-weight: 400;
    margin-right: 10px;
    padding: 2px 6px;
    font-size: 12px;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    color: #fff;
}

.admin-order-username:hover {
    /* opacity: 0.8; */
}
.admin-order-user {
    position: absolute;
    right: 0px;
    top: 100%;
    background-color: #222222;
    padding: 6px;
    border-radius: 8px;
    display: flex;
    /* box-shadow: 0 0 0 2000px #000000d6; */
    opacity: 0;
    z-index: 99999999;
    transition: all 200ms;
    transform: translateY(-10px);
    pointer-events: none;
    cursor: default;
    white-space: nowrap;
}

.admin-order-username:hover .admin-order-user {
    opacity: 1;
    transform: translateY(0px);
    pointer-events: all;
    transition: all 200ms 200ms;
}

.admin-order-user .admin-order-user-photo {
    display: block;
    height: 40px;
    width: 40px;
    background-color: #000;
    margin: 4px;
    border-radius: 50%;
    object-fit: cover;
}

.admin-order-user-email-container {
    height: 48px;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.admin-order-user-whatsapp {
    color: #fff;
    margin: 0px;
    padding: 0px;
    margin-top: 2px;
    padding: 4px;
    font-weight: 500;
    transition: all 200ms;
}

.admin-order-user-whatsapp:hover {
    color: rgb(6, 192, 6);
}

.admin-order-menu img {
    height: 30px;
    /* margin: -20px 0px; */
    margin-top: 2px;
}

.admin-order-products {
    width: calc(100% + 120px);
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
    margin-right: -120px;
}

.admin-order-product {
    padding: 5px;
    border-radius: 8px;
    background-color: #ae2f2f;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    padding-bottom: 10px;
    transition: all 200ms;
    min-width: 60px;
    text-align: center;
}

.admin-order-product.blinking {
    animation: admin-order-p 1s ease-in-out 0ms infinite normal;
}

@keyframes admin-order-p {
    0% {
        background-color: normal;
    }
    50% {
        background-color: #000;
    }
}

.admin-order-product:hover {
    z-index: 9999;
}

.admin-order-product img {
    display: block;
    height: 60px;
    width: 60px;
    border-radius: 6px;
    content: "teste";
    position: relative;
}

.admin-order-product:hover img,
.admin-order-product:hover .admin-order-sku {
    z-index: 100;
}

.admin-order-product img.expand {
    position: absolute;
    right: calc(100% + 5px);
    top: 0px;
    height: 220px;
    width: 220px;
    z-index: 99;
    opacity: 0;
    pointer-events: none;
    transition: all 100ms, border-color 600ms;
    transform: translateX(-10px);
    box-shadow: 0 0 0 2000px #000000e4;
    border: 6px solid;
    border-color: inherit;
    border-radius: 20px;
    background-color: #fff;
}

.admin-order-product:hover img.expand {
    opacity: 1;
    transform: translateX(0px);
    transition: all 200ms 100ms, border-color 600ms;
}

.admin-order-product-btns {
    position: absolute;
    left: 3px;
    top: 100%;
    background-color: #6e6e6e;
    border-radius: 8px;
    /* width: 130px; */
    z-index: 100;
    opacity: 0;
    transition: all 200ms;
    font-weight: 600;
    pointer-events: none;
    text-align: center;
    white-space: nowrap;
}

.admin-order-product:hover .admin-order-product-btns {
    opacity: 1;
    pointer-events: all;
}

.admin-order-product-btns div {
    padding: 8px 10px;
    background-color: #6200ff;
    border-radius: 6px;
    margin: 8px;
    cursor: pointer;
    transition: all 200ms;
}

.admin-order-product-btns div.engrave {
    background-color: #ff970f;
}

.admin-order-product-btns div.ready {
    background-color: #0080ff;
}

.admin-order-product-btns div.disabled {
    background-color: #545454;
    color: #cccccc;
    pointer-events: none;
}

.admin-order-product-btns div:first-of-type {
    margin-bottom: 6px;
}

.admin-order-number {
    background-color: #ae2f2f;
    color: #fff;
    padding: 1px 6px;
    border-radius: 4px;
    font-weight: 500;
    width: fit-content;
}

.admin-order-sku {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5px;
    background-color: inherit;
    padding: 2px 6px;
    font-weight: 500;
    border-radius: 4px;
    outline: 1px solid #ffffff72;
    font-size: 11px;
}

.admin-order-search {
    position: fixed;
    bottom: 0px;
    right: 0px;
    background: linear-gradient(to top, #1a1a1a 85%, #00000000 100%);
    /* outline: 1px solid #f00; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 200px);
    padding-top: 30px;
    z-index: 99999;
}

.admin-order-search input {
    background-color: #292929;
    border: none;
    color: #fff;
    width: 900px;
    padding: 10px 20px;
    font-size: 18px;
    box-sizing: border-box;
    border-radius: 8px;
    transition: all 200ms;
    outline: 1px solid #1567d200;
}

.admin-order-search input:focus {
    outline: 1px solid #1567d2;
}

.admin-order-check {
    min-width: 24px;
    min-height: 24px;
    border-radius: 4px;
    margin-right: 14px;
    background-color: #363636;
    position: relative;
    cursor: pointer;
    position: absolute;
    left: -40px;
    top: 50%;
    transform: translateY(-50%);
}

.admin-order-checked {
    top: 0px;
    left: 0px;
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: #1567d2;
    border-radius: 4px;
    opacity: 0;
    scale: 0.1;
    transition: all 200ms;
}

.admin-order-checked.checked {
    opacity: 1;
    scale: 1;
}

.admin-order-checked::after {
    content: "";
    position: absolute;
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.admin-order-container .loader {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 999;
    transform: translateX(-50%) translateY(-50%);
    margin-left: 100px;
}

.admin-order-container .loader.hidden {
    opacity: 0;
}

.admin-option-confirm {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translateX(-50%) translateY(-50%);
    font-weight: 600;
    background-color: #000;
    padding: 3px 6px;
    border-radius: 4px;
    box-shadow: 0 0 5px 5px #00000085;
    outline: 1px solid #ffffff76;
    cursor: pointer;
    opacity: 0;
    transition: all 200ms;
    z-index: 9999;
}

.admin-order-product:hover .admin-option-confirm {
    opacity: 1;
}

.admin-order-search-container {
    position: relative;
    width: 900px;
    font-size: 13px;
    margin-bottom: 12px;
}

.admin-order-selected-btns {
    display: flex;
    justify-content: flex-end;
    margin: 0px;
    max-height: 0px;
    transition: all 200ms;
    opacity: 0;
}

.admin-order-selected-btns.shown {
    margin: 0px 0px 12px;
    max-height: 200px;
    opacity: 1;
}

.admin-order-selected-btns {
    display: flex;
    align-items: center;
}

.admin-selected-btn {
    background-color: #1567d2;
    text-align: center;
    padding: 2px 8px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    border-radius: 4px;
    transition: all 200ms;
    cursor: pointer;
    position: relative;
}

.admin-selected-btn:hover {
    opacity: 0.7;
}

.admin-order-search-input {
    position: relative;
}

.admin-search-qtty {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 18px;
    font-size: 15px;
    display: flex;
    align-items: center;
}

.admin-selected-orders {
    background-color: #121212;
    min-height: 28px;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    line-height: 11px;
    text-align: center;
    cursor: pointer;
}

.admin-selected-orders strong {
    margin-right: 3px;
}

.admin-selected-orders.only-selected {
    background-color: #1567d2;
    font-size: 12px;
}

.user-cut-hour {
    position: absolute;
    top: 0px;
    right: 100%;
    margin-right: 8px;
    padding: 2px 6px;
    background-color: #000;
    white-space: nowrap;
}

.admin-cancel-modal-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999999999;
    opacity: 0;
    transition: all 200ms;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin-cancel-modal-bg.shown {
    opacity: 1;
    pointer-events: all;
}

.admin-cancel-modal {
    background-color: #0c0c0c;
    color: #fff;
    width: 95%;
    max-width: 500px;
    height: fit-content;
    max-height: 530px;
    border-radius: 20px;
    scale: 0.1;
    transition: all 300ms;
    box-sizing: border-box;
    display: flex;
    border: 1px solid rgba(128, 128, 128, 0.443);
    font-size: 16px;
    /* transform: translateY(1000px); */
    padding: 20px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}

.admin-cancel-modal-bg.shown .admin-cancel-modal {
    scale: 1;
}

.admin-cancel-modal textarea {
    background-color: #292929;
    border: none;
    margin-top: 20px;
    width: 100%;
    border-radius: 10px;
    outline: 1px solid #1567d200;
    transition: all 200ms;
    padding: 10px;
    box-sizing: border-box;
    color: #fff;
    max-width: 460px;
    min-width: 460px;
}

.admin-cancel-modal textarea:focus {
    outline: 1px solid #1567d2;
}

.admin-cancel-modal-btn {
    background-color: rgb(181, 1, 1);
    padding: 2px 10px;
    margin-top: 14px;
    margin-left: auto;
    border-radius: 6px;
    transition: all 200ms;
    cursor: pointer;
    font-weight: 500;
}

.admin-cancel-modal-btn:hover {
    opacity: 0.6;
}

.order-marketplace-separator {
    /* margin-top: 20px; */
    /* padding: 0px; */
    /* outline: 2px solid #f00; */
    /* margin-right: 30px; */
    border: 2px solid #fff;
    /* box-sizing: border-box; */
    border-bottom: none;
    max-width: 980px;
    width: calc(100% - 20px);
    text-align: center;
    border-radius: 10px 10px 0px 0px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 10px; */
    /* padding: 4px 0px; */
    position: inherit;
}

.sub-marketplace-name {
    background-color: #000;
    margin-left: 8px;
    padding: 2px 8px;
    border-radius: 6px;
    outline: 1px solid #fff;
}

.order-marketplace-ending {
    /* margin-top: 5px; */
    padding: 10px 0px 10px;
    /* margin-right: 30px; */
    border: 2px solid #fff;
    /* box-sizing: border-box; */
    border-top: none;
    max-width: 980px;
    width: calc(100% - 20px);
    text-align: center;
    border-radius: 0px 0px 10px 10px;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.order-marketplace-separator img {
    height: 50px;
    width: 50px;
    /* margin: -20px 0px; */
    /* margin-bottom: -10px; */
    /* outline: 1px solid #f00; */
    margin: 6px 0px;
}

.admin-order .admin-order-border {
    position: absolute;
    border: 2px solid #fff;
    border-bottom: none;
    border-top: none;
    /* max-width: 980px; */
    width: calc(100% + 80px);
    /* width: 100%; */
    height: calc(100% + 10px);
    /* left: -36px; */
    right: -27px;
    box-sizing: border-box;
    /* right: 0px; */
    top: -8px;
    padding-bottom: 20px;
    pointer-events: none;
    /* outline: 1px solid #f00; */
    /* margin: 0px; */
}

.printing-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000000e6;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    opacity: 0;
    transition: all 200ms;
}

.printing-loading .loader {
    position: relative;
    transform: none;
    margin: 4px 0px 0px;
    /* outline: 1px solid #f00; */
    left: auto;
    top: auto;
    scale: 0.4;
}

.admin-selected-btn.printing-show {
    pointer-events: none;
}

.admin-selected-btn.printing-show .printing-loading {
    opacity: 1;
}

.admin-order-price {
    margin-right: 14px;
    /* margin-bottom: 3px; */
    font-size: 14px;
    line-height: 13px;
}

.order-modal-admin-bg-2 {
    background-color: rgba(0, 0, 0, 0.85);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 999999;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 300ms;
}

.order-modal-admin {
    background-color: #0c0c0c;
    width: 95%;
    max-width: 600px;
    height: fit-content;
    /* max-height: 600px; */
    border-radius: 20px;
    scale: 0.1;
    transition: all 600ms, transform 600ms;
    box-sizing: border-box;
    display: flex;
    border: 1px solid rgba(128, 128, 128, 0.443);
    font-size: 18px;
    transform: translateY(-400px);
    padding: 20px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.order-modal-grouped {
    display: flex;
    max-height: 90dvh;
    max-width: min(900px, 90vw);
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.grouped-product {
    background-color: #313131;
    font-size: 14px;
    text-align: center;
    margin: 6px;
    padding: 6px;
    position: relative;
    border-radius: 6px;
    display: flex;
}

.grouped-product img {
    width: 70px;
    height: 70px;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 6px;
}

.grouped-sku {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #000;
    padding: 1px 4px;
    border-radius: 3px;
    outline: 1px solid #ffffff93;
    font-weight: 500;
}

.grouped-qtty {
    background-color: #f00;
    position: absolute;
    font-weight: 600;
    text-align: center;
    min-width: 20px;
    min-height: 20px;
    line-height: 20px;
    border-radius: 8px;
    right: 0px;
    top: 0px;
    padding: 0px 4px;
    box-sizing: border-box;
}

.admin-order-group-button {
    margin-right: 8px;
    background-color: #1a1a1a;
    padding: 3px 8px;
    border-radius: 4px;
    cursor: pointer;
}

.admin-order-group-button:hover {
    opacity: 0.8;
}

.order-modal-admin-bg-2.shown {
    opacity: 1;
    pointer-events: all;
}

.order-modal-admin-bg-2.shown .order-modal-admin {
    scale: 1;
    transform: translateY(0px);
}

.order-modal-admin.order-modal-plp {
    height: 95vh;
    width: calc(95vh / 6 * 4);
    padding: 10px;
}

.admin-orders-container {
    display: flex;
    /* border: 1px solid #0f0; */
    position: relative;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
}

.print-all-btn {
    margin-bottom: 10px;
    font-size: 13px;
    margin-left: auto;
    margin-right: 10px;
    padding: 2px 8px;
    background-color: #111111;
    border-radius: 4px;
    cursor: pointer;
    transition: all 200ms;
}

.print-all-btn:hover:not(.disabled) {
    background-color: #404040;
}

.print-all-btn.disabled {
    opacity: 0.6;
    cursor: progress;
}

.admin-autoprint-container {
    /* outline: 1px solid #f00; */
    position: sticky;
    width: 0px;
    /* height: 100vh; */
    box-sizing: border-box;
    padding: 20px;
    padding-top: 16px;
    padding-right: 12px;
    overflow: hidden;
    transition: all 500ms, background-color 200ms;
    opacity: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: wrap;
    /* align-items: center; */
    padding-bottom: 200px;
    align-content: flex-start;
    /* z-index: 9999; */
    /* background-color: #171717; */
    outline: 2px solid #ffffff0d;
}

.admin-autoprint-container.focus {
    background-color: #262626;
    border: 2px solid #fff;
    border-radius: 8px;
}

.admin-autoprint-container.on {
    width: 340px;
    max-width: 340px;
    opacity: 1;
    overflow: visible;
}

.admin-autoprint-container:not(.on) {
    max-height: calc(100vh - 20px);
}

.autoprint-toggle {
    position: fixed;
    top: 0px;
    right: 0px;
    opacity: 0.5;
    cursor: pointer;
    transition: all 500ms;
    padding: 4px 10px;
    /* border-radius: 6px; */
    background-color: none;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    width: 130px;
    white-space: nowrap;
    align-items: center;
    background-color: #1a1a1a;
    z-index: 99999;
    font-size: 14px;
}

.autoprint-toggle:hover {
    opacity: 0.8;
}

.autoprint-toggle.on {
    opacity: 1;
    color: #1567d2;
    font-weight: 600;
    background-color: #1a1a1a;
    width: 265px;
}

.autoprint-order {
    background-color: #111111;
    margin-top: 8px;
    margin-right: 8px;
    padding: 8px;
    border-radius: 8px;
    /* width: 80px; */
    height: fit-content;
    /* outline: 1px solid #f00; */
    display: flex;
    flex-direction: column;
    /* margin: 0px; */
    font-size: 13px;
    width: 210px;
}

.autoprint-order img {
    width: 70px;
    height: 70px;
    border-radius: 8px;
    margin-top: 6px;
}

.autoprint-order .admin-order-btns {
    margin: 0px;
    font-size: 12px;
}

.autoprint-order .admin-order-btns div {
    font-size: 11px;
}

.autoprint-order .admin-order-username {
    width: fit-content;
    max-width: calc(100% - 40px);
    box-sizing: border-box;
    margin: 6px 0px;
}

.autoprint-order .autoprint-marketplace {
    width: 30px;
    height: fit-content;
    margin: 0px;
    margin-left: auto;
}

.autoprint-username {
    max-width: 100%;
    width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
}

.autoprint-secondrow {
    display: flex;
    align-items: center;
}

.autoprint-thirdrow {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
}

.autoprint-order .admin-order-user {
    width: 200px;
    bottom: 100%;
    top: auto;
    left: 0px;
}

.autoprint-order .admin-order-user-email-container {
    width: 100%;
}

.thirdrow-left {
    padding: 4px;
    outline: 1px solid rgba(255, 255, 255, 0.14);
    border-radius: 10px;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-evenly; */
}

.autoprint-confirm {
    margin-bottom: 6px;
    background-color: rgb(40, 40, 40);
    padding: 2px 6px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    /* outline: 1px solid #f00; */
    min-width: 70px;
    text-align: center;
}

.autoprint-cancel {
    background-color: #871d1d;
}

.autoprint-confirm:hover {
    opacity: 0.8;
}

.autoprint-date {
    background-color: #000;
    padding: 1px 3px;
    border-radius: 4px;
    font-size: 12px;
}

.admin-order.admin-order-static::after {
    border: none;
}

.renderIfVisible .admin-order.admin-order-static {
    width: 100%;
    /* margin: 0px; */
    padding: 8px;
    transform: none;
    padding-right: 114px;
}

.admin-order.admin-order-static2 {
    transform: none;
}

.renderIfVisible.admin-order-static {
    padding: 0px !important;
    transform: none;
}

.autoprint-sku {
    background-color: #000;
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 600;
    padding: 1px 6px;
    border-radius: 4px;
    outline: 1px solid #ffffff41;
}

.thirdrow-right {
    position: relative;
}

.starbox-qtty {
    color: #ffffffb9;
    font-weight: 400;
}

.plp-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    border-radius: 20px;
}

.plp-loading.hidden {
    opacity: 0;
    pointer-events: none;
}

.plp-loading .loader {
    position: relative;
}

.autoprint-toggle img {
    width: 110px;
}

.admin-order-filter {
    padding: 4px 10px;
    margin-right: 10px;
    background-color: #1b1b1b;
    border-radius: 6px;
    position: relative;
}

.admin-filter-select {
    position: absolute;
    bottom: calc(100% + 2px);
    left: 50%;
    transform: translateX(-50%);
    background-color: #121212;
    padding: 8px;
    border-radius: 6px;
    font-weight: 500;
    box-shadow: 0 0 0 2000px #000000ce;
    opacity: 0;
    pointer-events: none;
    transition: all 200ms;
}

.admin-order-filter:hover .admin-filter-select {
    opacity: 1;
    pointer-events: all;
}

.admin-filter-select div {
    margin-bottom: 6px;
    padding: 2px 6px;
    border-radius: 4px;
    background-color: #222222;
    cursor: pointer;
}

.admin-filter-select div:hover {
    filter: brightness(0.7);
}

.admin-filter-select div:last-of-type {
    margin: 0px;
}

.filter-selected {
    outline: 2px solid #ffffffa9;
}

.admin-marketplace-sub {
    /* position: absolute; */
    /* top: calc(50% - 1px); */
    /* transform: translateY(-50%); */
    /* left: calc(100% + 5px); */
    background-color: #000;
    padding: 1px 3px;
    font-size: 10px;
    outline: 1px solid #fff;
    border-radius: 4px;
    font-weight: 500;
    margin-left: 4px;
}

.admin-order-marketplace-img {
    display: flex;
    position: relative;
    margin-top: 0px;
    align-items: center;
}

.admin-order-marketplace-img img {
    margin: -10px 0px;
    height: 30px;
}

img.order-starbox-logo {
    height: 8px;
    filter: invert(1);
    margin-left: 6px;
    margin-top: -4px;
}

.admin-order-dates {
    position: absolute;
    right: 8px;
    top: 8px;
    font-size: 9px;
    line-height: 11px;
    text-align: center;
    z-index: 9999;
    background-color: #111111;
}

.admin-order-date {
    background-color: #000;
    padding: 2px 4px;
    border-radius: 6px;
    margin-bottom: 8px;
    padding-top: 1px;
}

.admin-order-date.packed {
    background-color: #1567d2;
}

.admin-order-date.collected {
    background-color: #0a770a;
}

.admin-order-date.cancelled {
    background-color: #c93838;
}

.admin-order-date.cancelled2 {
    background-color: #a7631c;
}

.admin-order-date-number {
    font-size: 12px;
    font-weight: 500;
}

.buyer-name {
    background-color: #000;
    display: flex;
    flex-direction: column;
    padding: 0px 6px;
    border-radius: 4px;
    height: fit-content;
}

.buyer-name span {
    font-size: 8px;
    line-height: 8px;
    opacity: 0.6;
}

.admin-order .order-bling-icon {
    position: relative;
    margin: 0px;
    bottom: auto;
    left: auto;
    margin-top: 0px;
    margin-left: 6px;
    opacity: 0.9;
    font-size: 11px;
    padding: 4px 6px;
    font-weight: 400;
    transform: none;
}

.admin-order img.order-bling-icon {
    height: 12px;
    opacity: 0.7;
}

.admin-order .order-bling-icon.manual {
    color: #c93838;
    font-weight: 700;
}

.autoprint-marketplace-container {
    margin-left: auto;
    position: relative;
}

.admin-autoprint-container .order-bling-icon {
    font-size: 8px;
    padding: 2px 4px;
    bottom: -4px;
}

.admin-autoprint-container img.order-bling-icon {
    height: 10px;
    width: fit-content;
    border-radius: 4px;
}

.autoprint-order .admin-marketplace-sub {
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    font-size: 9px;
    opacity: 0.8;
    outline: 1px solid #ffffff5e;
    border-radius: 3px;
    margin-right: 4px;
}

.admin-cancel-reason-show {
    position: absolute;
    bottom: calc(100% + 4px);
    left: 50%;
    width: 180px;
    padding: 8px;
    outline: 1px solid #ffffff7e;
    opacity: 0;
    transition: all 200ms;
    transform: translateX(-50%) translateY(10px);
    pointer-events: none;
}

.admin-cancel-order.canceled:hover .admin-cancel-reason-show {
    transform: translateX(-50%);
    opacity: 1;
}

.admin-cancel-order.canceled:hover {
    background-color: #c93838;
    cursor: default;
}

.admin-cancel-order.canceled {
    position: relative;
    background-color: #c93838;
}

.admin-order-qtty {
    position: absolute;
    top: -2px;
    right: -2px;
    background-color: #000;
    font-size: 14px;
    z-index: 99;
    min-width: 22px;
    min-height: 22px;
    line-height: 22px;
    border-radius: 11px;
    font-weight: 600;
    outline: 1px solid #ffffff60;
}

.admin-full-volumes {
    font-size: 14px;
    margin-bottom: 4px;
    width: 100%;
    display: flex;
}

.admin-full-status {
    background-color: #202020;
    width: fit-content;
    padding: 2px 6px;
    margin-right: 6px;
    border-radius: 4px;
}

.admin-full-status span {
    font-weight: 600;
}

.orders-qtty {
    border: 2px solid;
    border-color: inherit;
    position: sticky;
    top: 50px;
    left: 0px;
    z-index: 9999999;
    padding: 4px;
    border-radius: 4px;
    background-color: #1a1a1a;
    align-self: flex-end;
    margin-bottom: -40px;
    min-width: 50px;
    text-align: center;
    font-size: 18px;
}
