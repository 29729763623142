.admin-config-modal-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999999999;
    opacity: 0;
    transition: all 200ms;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin-config-modal-bg.shown {
    opacity: 1;
    pointer-events: all;
}

.admin-config-modal {
    background-color: #0c0c0c;
    color: #fff;
    width: 95%;
    max-width: 500px;
    height: fit-content;
    max-height: 700px;
    border-radius: 20px;
    scale: 0.1;
    transition: all 300ms;
    box-sizing: border-box;
    display: flex;
    border: 1px solid rgba(128, 128, 128, 0.443);
    font-size: 14px;
    /* transform: translateY(1000px); */
    padding: 20px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* align-items: flex-end; */
    overflow: hidden;
    word-break: break-all;
}

.admin-config-modal-bg.shown .admin-config-modal {
    scale: 1;
}

.admin-config-printers {
    width: 100%;
    background-color: #272727;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0px;
    flex-wrap: wrap;
    box-sizing: border-box;
    position: relative;
    margin-top: 12px;
}

.admin-config-printers:first-of-type {
    margin-bottom: 20px;
}

.admin-config-printer {
    background-color: #131313;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 10px;
    margin-left: 10px;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
}

.admin-config-printer div:first-of-type {
    font-size: 16px;
}

.admin-config-printer.selected {
    outline: 2px solid #1567d2;
    background-color: #263241;
}

.admin-config-printers-title {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
}

.admin-printer-test {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 2px 8px;
    background-color: #1567d2;
    border-radius: 4px;
    font-weight: 600;
    font-size: 13px;
    cursor: pointer;
    transition: all 200ms;
}

.admin-printer-test:hover {
    opacity: 0.7;
}

.admin-autoprint-config .switch {
    position: relative;
    display: inline-block;
    width: 43px;
    height: 21px;
    margin: 0px;
    margin-right: 10px;
}

.admin-autoprint-config .switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #1d1d1d;
    transition: 0.4s;
    border-radius: 34px;
}

.admin-autoprint-config .switch.checked .slider {
    background-color: #1567d2;
}

.admin-autoprint-config .switch .slider::before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 4px;
    bottom: 4px;
    background-color: #b1b1b1;
    transition: 0.4s;
    border-radius: 50%;
}

.admin-autoprint-config .switch.checked .slider::before {
    transform: translateX(22px);
    background-color: #ffffff;
}

.admin-autoprint-config {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.print-area {
    max-width: 100%;
    min-width: 100%;
    margin-top: 20px;
    min-height: 100px;
    border-radius: 6px;
    background-color: #0c0c0c;
    color: #fff;
    padding: 10px;
    box-sizing: border-box;
    border: none;
    outline: 1px solid #ffffff2a;
    transition: outline 200ms;
}

.print-area:focus {
    outline: solid 1px #1567d2;
}

.admin-printer-send {
    background-color: #1567d2;
    padding: 2px 10px;
    margin-top: 10px;
    border-radius: 4px;
    cursor: pointer;
}

.admin-printer-send:hover {
    opacity: 0.8;
}
